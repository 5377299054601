import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ChatApi from '../../api/chat';
import { useLocale } from "../../app_context/locale.context";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green, grey } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Stack from '@mui/material/Stack';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';

import createSpeechServicesPonyfill from 'web-speech-cognitive-services';
import SpeechRecognition, { useSpeechRecognition } from '../../libs/speech-recognition/index';

let isSpeechRecognitionInitialized = false;

function VoiceRecognition(props) {
  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  let {locale} = useLocale();
  let [text, setText] = React.useState('');
  let [textModified, setTextModified] = React.useState('');
  let [language, setLanguage] = React.useState(locale.name);

  const {
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
  } = useSpeechRecognition();


  const handleDone = async (apply) => {
    SpeechRecognition.abortListening();
    isSpeechRecognitionInitialized = false;
    await resetTranscript();
    await setTextModified('');
    if (!!apply) {
      props.onDone(text);
    } else {
      props.onDone('');
    }
  };

  const handlePrimaryButtonClicked = async () => {

    if (listening) {
      SpeechRecognition.abortListening();
    } else {
      await setTextModified(text);
      await resetTranscript();
      SpeechRecognition.startListening({
        continuous: true,
        language: language
      });
    }
    
  };

  const handleLanguageChanged = async (event, newLanguage) => {
    if (newLanguage === null) {
      return;
    }
    setLanguage(newLanguage);
  };

  React.useEffect(() => {
    setText(`${textModified}${textModified!=''?' ':''}${transcript}`);
  }, [transcript]);

  React.useEffect(() => {

    resetTranscript();
    setTextModified('');
      
    ChatApi.AzureAccessToken().then((resp) => {
      if (resp.data.code === 200) {
        let region = resp.data.data.region;
        let token = resp.data.data.token;
       
        if (!isSpeechRecognitionInitialized) {
          isSpeechRecognitionInitialized = true;
          const { SpeechRecognition: AzureSpeechRecognition } = createSpeechServicesPonyfill({
            credentials: {
              region: region,
              authorizationToken: token
            }
          });
          SpeechRecognition.applyPolyfill(AzureSpeechRecognition);
        }

        SpeechRecognition.startListening({
          continuous: true,
          language: language
        });
      }
    });
    
    return () => {
      SpeechRecognition.abortListening();
      isSpeechRecognitionInitialized = false;
    };
  }, []);


  return (
    <Dialog
        fullScreen={fullScreen}
        open={props.open}
        aria-labelledby="voiceRecognition-dialog-title"
      >
        <DialogTitle id="voiceRecognition-dialog-title" sx={{textAlign: 'center'}}>
          {listening ? locale.Chat35.Interactive.VoiceRecognition.recognizing : locale.Chat35.Interactive.VoiceRecognition.title}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText sx={{textAlign: 'center', minHeight: 80}}>
          {transcript}
          </DialogContentText> */}
          <Box sx={{p: 3}}>
            <TextField
              disabled={listening}
              label={locale.Chat35.Interactive.VoiceRecognition.inputLabel}
              placeholder={locale.Chat35.Interactive.VoiceRecognition.inputPlaceholder}
              multiline
              fullWidth
              value={text}
              onChange={(event) => {
                setText(event.target.value);
              }}
            />
          </Box>
        </DialogContent>
        <Stack>
            <Box sx={{ m: 1, textAlign: 'center' }}>
              <ToggleButtonGroup
                disabled={listening}
                color="primary"
                value={language}
                exclusive
                onChange={handleLanguageChanged}
                aria-label="Platform"
              >
                <ToggleButton value="zh-HK">粤语(香港)</ToggleButton>
                <ToggleButton value="zh-CN">普通话(大陆)</ToggleButton>
                <ToggleButton value="en-US">English(US)</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}
              sx={{p: 5}}
            >
              <Box sx={{ m: 1, position: 'relative' }}>
                <Fab
                  aria-label="Cancel"
                  onClick={()=>handleDone(false)}
                >
                  <CloseIcon fontSize="large" />
                </Fab>
              </Box>
              <Box sx={{ m: 1, position: 'relative' }}>
                <Fab
                  aria-label="Recognizing"
                  color={listening ? 'error' : 'primary'}
                  onClick={handlePrimaryButtonClicked}
                >
                  {
                    listening ?
                    <StopIcon fontSize="large" />
                    :
                    <MicIcon fontSize="large" />
                  }
                </Fab>
                {listening && (
                  <CircularProgress
                    variant={listening ? 'indeterminate' : 'determinate'}
                    size={68}
                    value={0}
                    sx={{
                      color: green[500],
                      position: 'absolute',
                      top: -6,
                      left: -6,
                      zIndex: 1,
                    }}
                  />
                )}
              </Box>
              <Box sx={{ m: 1, position: 'relative' }}>
                <Fab
                  aria-label="send to chat"
                  color='success'
                  onClick={()=>handleDone(true)}
                >
                  <SendIcon fontSize="large" />
                </Fab>
              </Box>
            </Stack>
        </Stack>
      </Dialog>
  );
}
export default VoiceRecognition;