import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialog(props) {

  const handleClose = (confirm) => {
    props.onClose(confirm === true);
  };

  const handleYesClick = () => {
    handleClose(true);
  };

  const handleNoClick = () => {
    handleClose(false);
  };

  return (
    <Dialog
    open={props.open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title">
        {props.title}
    </DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
        {props.description}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleNoClick}>{props.buttonNO}</Button>
        <Button onClick={handleYesClick} autoFocus>{props.buttonYES}</Button>
    </DialogActions>
    </Dialog>
  );
}