import { Routes, Route, Navigate } from "react-router-dom"
import React from "react";
import ProtectedRoute from "./layouts/components/ProtectedRoute";
import SignIn from "./layouts/authentication/SignIn"
import SignInWithCode from "./layouts/authentication/SignInWithCode"
import Home from "./layouts/Home"
import Chat35 from "./layouts/chat/Chat35"
import Interactive from "./layouts/chat/Interactive"
import Settings from "./layouts/chat/Settings"


const getRoutes = () => {
    return (
        <Routes>
            <Route key="SignIn" path="SignIn" element={<SignIn />} />
            {/* <Route key="SignInWithCode" path="SignInWithCode" element={<SignInWithCode />} /> */}

            <Route element={<ProtectedRoute />}>
                <Route key="Home" path="Home" element={<Home />} />
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route key="Chat35" path="Chat35" element={<Chat35 />}>
                    <Route key="Chat35.Interactive" path="Interactive" element={<Interactive />} />
                    <Route key="Chat35.Settings" path="Settings" element={<Settings />} />
                </Route>
            </Route>
            
            <Route key="Any" path="*" element={<Navigate to="Chat35/Interactive" />}/>

        </Routes>
    );
};

export default getRoutes;
