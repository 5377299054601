import axios from "./index";
import Axios from "axios";
import { API_BASE_URL, LNG } from "../constants";
import { TC } from "../TranditionalChinese";
import { createParser, ParsedEvent, ReconnectInterval } from 'eventsource-parser';

const SPEECH_HUMANS = ['en-US-JennyMultilingualNeural', 'zh-CN-XiaoxiaoNeural', 'zh-HK-HiuMaanNeural', 'ja-JP-NanamiNeural'];
const LANGUAGE_HUMAN_MAP = {
  'en-US': SPEECH_HUMANS[0],
  'en': SPEECH_HUMANS[0],
  'zh-CN': SPEECH_HUMANS[1],
  'zh-HK': SPEECH_HUMANS[2],
  'zh': SPEECH_HUMANS[1],
  'ja-JP': SPEECH_HUMANS[3],
  'ja': SPEECH_HUMANS[3],
}

const azureAxios = Axios.create({
  headers: { "Content-Type": "application/ssml+xml", "X-Microsoft-OutputFormat": "audio-16khz-64kbitrate-mono-mp3", "User-Agent": "www.ailaoshi.net" },
  timeout: 120000,
});

const isZhCN = (text) => {
  return /[\u4e00-\u9fa5]/g.test(text);
};

const isJaJP = (text) => {
  // Is Japanese
  return /[\u3040-\u309F]/g.test(text);
};

const isZhHK = (text) => {
  for (let i = 0; i < text.length; i++) {
    let word = text[i];
    if (TC.indexOf(word)>=0) {
      return true;
    }
  }
};

const getLanguage = (text) => {
  if (isJaJP(text)) {
    return 'ja-JP';
  } else if (isZhHK(text)) {
    return 'zh-HK';
  } else if (isZhCN(text)) {
    return 'zh-CN';
  } else {
    return 'en-US';
  }
};

class ChatApi {

  static AzureAccessToken = () => {
    return axios.get(`user/get_cognitive_config`);
  };

  static Speech = (text) => {

    let audio = new Audio();
    let lang = getLanguage(text);
    let human = LANGUAGE_HUMAN_MAP[lang];

    ChatApi.AzureAccessToken().then((resp) => {
      if (resp.data.code === 200) {
        let token = resp.data.data.token;
        let region = resp.data.data.region;
        let url = `https://${region}.tts.speech.microsoft.com/cognitiveservices/v1`;
        text = `<speak version='1.0' xml:lang='${lang}'>
                  <voice xml:lang='${lang}'
                  name='${human}'>
                  ${text}
                  </voice>
                </speak>`;
        azureAxios.post(url, text, {
          headers: { 'Content-Type': 'application/ssml+xml', 
                    'X-Microsoft-OutputFormat': 'audio-16khz-64kbitrate-mono-mp3', 
                    'User-Agent': 'www.ailaoshi.net', 
                    'Authorization': `Bearer ${token}`},
          timeout: 120000,
          responseType: 'blob'
        })
        .then(response => {
          // Get the audio data from the response
          //let blob = response.data.slice(0, response.data.size, 'audio/wav')
          audio.src = URL.createObjectURL(response.data);
        })
        .catch(err => {
          console.error(err);
          var event = new Event('error');
          audio.dispatchEvent(event);
        });

      } else {
        var event = new Event('error');
        audio.dispatchEvent(event);
      }
    }).catch((err) => {
      console.error(err);
      var event = new Event('error');
      audio.dispatchEvent(event);
    });

    return audio;
  };

  static VoiceToText = (voice) => {

  };

  static PromptTemplates = () => {
    return axios.get(`user/get_prompt_templates`);
  };

  static Send = (data) => {
    return axios.post(`user/send_message`, data);
  };

  static SendStream = (data, cb) => {

    let url = `${API_BASE_URL}user/send_message_stream`;

    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json',
          'locale': LNG
      },
      timeout: 120000,
      body: JSON.stringify(data)
    })
    .then(response => {

      let replyResult = '';
      const reader = response.body.getReader();

      return new ReadableStream({

        async start(controller) {

          const decoder = new TextDecoder('utf-8');
          const pump = ({ done, value }) => {
            // When no more data needs to be consumed, close the stream
            if (done) {
              if (response.status == 200) {
                if (cb) cb({done: true, reply: replyResult});
              } else {
                if (cb) cb({done: true, reply: replyResult == '' ? response.statusText : replyResult, error: response});  
              }
              return;
            }
            // Enqueue the next data chunk into our target stream
            controller.enqueue(value);
            const textChunk = decoder.decode(value);
            replyResult += textChunk;
            // const jsonChunk = JSON.parse(textChunk);
            if (response.status == 200) {
              if (cb) {cb(textChunk)};
            }
            return reader.read().then(pump);
          };
          
          return reader.read().then(pump);
        }
      });
    })
    .catch((error) => {
      if (cb) cb({error});
    });

  }
}

export default ChatApi;
