import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DoneIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VoiceRecognition from './VoiceRecognition';
import StopIcon from '@mui/icons-material/Stop';
import { useLocale } from "../../app_context/locale.context";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ChatApi from "../../api/chat";
import LanguageIcon from '@mui/icons-material/Language';
import createSpeechServicesPonyfill from 'web-speech-cognitive-services';
import SpeechRecognition, { useSpeechRecognition } from '../../libs/speech-recognition/index';
import { FormHelperText } from '@mui/material';
import { API_BASE_URL, LNG } from "../../constants";


const MAX_LENGTH = 2000;
const LANGUAGES = ['中', '粤', 'EN', '日'];
const LANGUAGES_VALUE = ['zh-CN', 'zh-HK', 'en-US', 'ja-JP'];


function SendPanel(props) {

  let {locale} = useLocale();

  let [label, setLabel] = React.useState(null);
  let [isTextOverload, setTextOverload] = React.useState(false);
  let [text, setText] = React.useState('');
  let [isThinking, setThinking] = React.useState(false);
  let [languageIndex, setLanguageIndex] = React.useState(LANGUAGES_VALUE.indexOf(localStorage.getItem('SpeechRecognition.Language')) > -1 ? LANGUAGES_VALUE.indexOf(localStorage.getItem('SpeechRecognition.Language')) : 0);
  let [isRecording, setRecording] = React.useState(false);
  let [isLoadingConfig, setLoadingConfig] = React.useState(false);
  let [stopRecordingTick, setStopRecordingTick] = React.useState(null);
  let [sentBySpeech, setSentBySpeech] = React.useState(false);

  const { 
    browserSupportsSpeechRecognition, 
    isMicrophoneAvailable,
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening, } = useSpeechRecognition();

  const traceTextLength = (text) => {
    if (text.length === 0) {
      setLabel(null);
    } else {
      setLabel(`${text.length}/${MAX_LENGTH}`);
      setTextOverload(text.length > MAX_LENGTH);
    }
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    traceTextLength(event.target.value);
  };

  const startTickerForStopRecording = () => {
    if (stopRecordingTick) {
      clearTimeout(stopRecordingTick);
    }
    setStopRecordingTick(setTimeout(() => {
      SpeechRecognition.abortListening();
      setRecording(false);
      resetTranscript();
    }, 5000));
  };

  const stopRecording = () => {
    // Stop voice recognition
    if (stopRecordingTick) {
      clearTimeout(stopRecordingTick);
    }
    try{ SpeechRecognition.abortListening(); } finally {}
    try { resetTranscript(); } finally {}
    setRecording(false);
  }

  const handleVoiceRecognitionClick = () => {

    if (isLoadingConfig) {
      return;
    }
    setLoadingConfig(true);

    if (isRecording) {
      stopRecording();
      setLoadingConfig(false);
      return;
    }

    setSentBySpeech(true);

    ChatApi.AzureAccessToken().then((resp) => {
      if (resp.data.code === 200) {
        let region = resp.data.data.region;
        let token = resp.data.data.token;

        // Start voice recognition
        setRecording(true);
        resetTranscript();
       
        const { SpeechRecognition: AzureSpeechRecognition } = createSpeechServicesPonyfill({
          credentials: {
            region: region,
            authorizationToken: token
          }
        });
        SpeechRecognition.applyPolyfill(AzureSpeechRecognition);

        SpeechRecognition.startListening({
          continuous: true,
          language: LANGUAGES_VALUE[languageIndex]
        });

        startTickerForStopRecording();
      }
    }).catch((err) => {
      props.onError('Get token error.');
    }).finally(() => {
      setLoadingConfig(false);
    });
  };  


  const handleChangeLanguage = () => {
    let index = languageIndex + 1;
    if (index >= LANGUAGES.length) {
      index = 0;
    }
    setLanguageIndex(index);
    localStorage.setItem('SpeechRecognition.Language', LANGUAGES_VALUE[index]);
  };

  const handleSendClick = () => {
    if (isTextOverload || text.length === 0 || isThinking) {
      return;
    }

    // Stop voice recognition
    stopRecording();

    if (props.chatMode) {

      let newMessages = [...props.messages, {'role': 'user', 'content': text}];

      ChatApi.SendStream({
        messages: newMessages,
        temperature: props.messageConfig.temperature,
      }, resp => {
        if (resp.done) {

          if (resp.error) {
            setText(newMessages[newMessages.length - 1].content);
            newMessages = newMessages.slice(0, newMessages.length - 1);
            props.onError(resp.reply ? resp.reply : resp.error, newMessages);
            setThinking(false);
          } else if (props.onTypeDone) {
            newMessages = [...newMessages, {'role': 'assistant', 'content': resp.reply}];
            props.onTypeDone(newMessages, !!sentBySpeech);
            setSentBySpeech(false);
            setThinking(false);
          }
          return;
        }
        if (props.onTyping) {
          props.onTyping(resp);
        }
      });
      
      props.onSent(newMessages);

    } else {

      let newMessages = [...props.messages, {'role': 'user', 'content': text}];

      ChatApi.SendStream({
        messages: [props.messages[0], {'role': 'user', 'content': text}],
        temperature: props.messageConfig.temperature,
      }, resp => {
        if (resp.done) {

          if (resp.error) {
            setText(newMessages[newMessages.length - 1].content);
            newMessages = newMessages.slice(0, newMessages.length - 1);
            props.onError(resp.reply ? resp.reply : resp.error, newMessages);
            setThinking(false);
          } else if (props.onTypeDone) {
            newMessages = [...newMessages, {'role': 'assistant', 'content': resp.reply}]
            props.onTypeDone(newMessages, !!sentBySpeech);
            setSentBySpeech(false);
            setThinking(false);
          }
          return;
        }
        if (props.onTyping) {
          props.onTyping(resp);
        }
      });
      
      props.onSent(newMessages);

      // ChatApi.Send({
      //   messages: newMessages,
      //   temperature: props.messageConfig.temperature,
      // }).then(async (resp) => {
      //   if (resp.data.code === 200) {
      //     newMessages = [...newMessages, {'role': 'assistant', 'content': resp.data.data}];
      //     props.onReply(newMessages, !!sentBySpeech);
      //     setSentBySpeech(false);
      //   } else {
      //     setText(newMessages[newMessages.length - 1].content);
      //     newMessages = newMessages.slice(0, newMessages.length - 1);
      //     props.onError(resp.data.message, newMessages);
      //   }
      // }).catch((error) => {
      //   setText(newMessages[newMessages.length - 1].content);
      //   newMessages = newMessages.slice(0, newMessages.length - 1);
      //   props.onError(typeof error === 'string' ? error : error.message, newMessages);
      // }).finally(() => {
      //   setThinking(false);
      //   props.onFinished(newMessages);
      // });

      // props.onSent(newMessages);
    }
    
    setText('');
    traceTextLength('');
    setThinking(true);
  };

  React.useEffect(() => {
    setText(`${text}${text!=''?' ':''}${finalTranscript}`);
    return () => {
      resetTranscript();
    };
  }, [finalTranscript]);

  React.useEffect(() => {

    startTickerForStopRecording();

  }, [interimTranscript]);

  React.useEffect(() => {
  }, [props.messages]);

  React.useEffect(() => {
  }, [props.messageConfig]);

  React.useEffect(() => {

    return () => {
      if (isRecording) {
        SpeechRecognition.abortListening();
      }
    }
  }, []);


  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={1}
    >
      <Box pl={1} pr={1}>
        <TextField
          disabled={isRecording}
          fullWidth={true}
          autoComplete="off"
          multiline={true}
          rows={2}
          label={label}
          InputProps={{ 
            disableUnderline: true
          }}
          value={text}
          variant="filled"
          onChange={handleTextChange}
          error={isTextOverload}
          placeholder={locale.Chat35.Interactive.VoiceRecognition.inputPlaceholder}
        />
      </Box>
      <Stack 
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={0}>
          
        {props.enableSpeechRecognition &&
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            width: '100%',
            bgcolor: 'rgba(0, 0, 0, 0.05)',
            ml: 1,
            mr: 1,
            borderRadius: '5px'
          }}
        >
          <Box
            sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
          >
            <Button
              disabled={isRecording}
              onClick={ handleChangeLanguage }
              startIcon={<LanguageIcon/>}
            >
              {LANGUAGES[languageIndex]}
            </Button>
            <small>
              {interimTranscript}
            </small>
          </Box>
          <Box>
            <IconButton 
              disabled={props.isThinking || !isMicrophoneAvailable || isLoadingConfig}
              onClick={handleVoiceRecognitionClick} 
              color={'primary'}
            >
              {
              isRecording ?
              <StopIcon color="error"/>
              :
              <MicIcon/>
              }               
            </IconButton>
          </Box>
        </Stack>
        }
        <Box pr={1}>
          <Button 
            sx={{whiteSpace: 'nowrap'}}
            disabled={isThinking || isTextOverload || text.length === 0}
            variant="contained"
            endIcon={<SendIcon />}
            onClick={handleSendClick} 
          >
            {locale.Chat35.Interactive.VoiceRecognition.send}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}
export default SendPanel;