let baseUrl = null;
if (process.env.API_BASE_URL) {
    baseUrl = process.env.REACT_APP_API_BASE_URL;
} else {
    baseUrl = "/api/";
}

export const LNG = navigator.language ? navigator.language : 'zh-CN';

export const API_BASE_URL = baseUrl;
export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const AUTH_LOGOUT_URL = process.env.REACT_APP_AUTH_LOGOUT_URL;
export const AUTH_REDIRECT_URI = process.env.REACT_APP_AUTH_REDIRECT_URI;
