export const TruncateText = (text, length) => {
    if (text.length > length) {
        return text.substring(0, length) + '...';
    }
    return text;
};

export const TruncateText2 = (text, length) => {
    if (text == null || text.trim() == '') {
        return '-';
    }
    if (!length) {
        length = 18
    }
    if (length >= text.trim().length) {
        return text
    }
    return text.trim().length > length ? text.trim().substring(0, Math.floor(length/2)) + '...' + text.trim().substring(text.trim().length - Math.floor(length/2)) : text;
}