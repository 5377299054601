import { Outlet } from 'react-router-dom';
import { useAuth } from "../../app_context/auth.context";
import { AUTH_URL } from "../../constants";

export default function ProtectedRoute() {
    let { user } = useAuth();
    return (<>
        {(!user || !user.token || user.token === "") ? 
        /*(<Navigate to="/SignIn" />)*/
        window.location.replace(AUTH_URL)
        : 
        (<Outlet />)}
    </>);
};
