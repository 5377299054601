import { createTheme, ThemeProvider } from '@mui/material/styles'
import "./App.css"
import getRoutes from "./routes"
import * as locales from '@mui/material/locale';
import { LNG } from './constants';
import * as appLocales from './app-locale';
import * as React from 'react';
import { LocaleProvider } from './app_context/locale.context';


function App() {

  const [locale, setLocale] = React.useState(LNG.replace('-', ''));

  const theme = createTheme({}, locales[locale]);
  let appLocale = appLocales[locale];
  if (!appLocale) {
    appLocale = appLocales['enUS'];
  }
  return (
    <LocaleProvider value={appLocale}>
      <ThemeProvider theme={theme}>
        { getRoutes() }
      </ThemeProvider>
    </LocaleProvider>
  );
}

export default App;
