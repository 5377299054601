import Axios from "axios";
import { API_BASE_URL, LNG } from "../constants";
import { AUTH_LOGOUT_URL } from "../constants";

const axios = Axios.create({
  baseURL: `${API_BASE_URL}`,
  headers: { "Content-Type": "application/json", "locale": LNG },
  timeout: 120000,
});

const cleanToken = () => {
  let user = localStorage.getItem("user");
  user = JSON.parse(user);
  delete user.token;
  localStorage.setItem("user", JSON.stringify(user));
  window.location.replace(AUTH_LOGOUT_URL);
};

axios.interceptors.request.use(
  (config) => {
    return Promise.resolve(config);
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    // check response is json or not
    if (response.headers["content-type"] !== "application/json") {
      return Promise.resolve(response);
    }
    if (response.data.code === 200) {
      response.data.success = true
      return Promise.resolve(response)
    }
    if (response.data.code === 401) {
      cleanToken();
      return;
    }
    return Promise.reject(`${response.data.code},${response.data.msg}`)
  },
  (error) => {
    if(error && error.response && error.response.status === 401) {
      cleanToken();
      return;
    } else {
      return Promise.reject(error);
    }
  }
);

export default axios;
