import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { useLocale } from "../../app_context/locale.context"

function ConversationPicker(props) {

  let { locale } = useLocale();

  let conversationsData = localStorage.getItem('conversations');
  if (conversationsData) {
    let conversationsDataTmp = JSON.parse(conversationsData);
    conversationsData = [];
    // Correct the data
    for (let i = 0; i < conversationsDataTmp.length; i++) {
      if (conversationsDataTmp[i] instanceof Array && conversationsDataTmp[i].length > 0) {
        conversationsData.push(conversationsDataTmp[i]);
      }
    }
    localStorage.setItem('conversations', JSON.stringify(conversationsData));
  }
  if (!(conversationsData instanceof Array)) {
    conversationsData = [];
  }
  let [conversations, setConversations] = React.useState(conversationsData);
  

  return (
    <>
      
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {locale.Chat35.Interactive.Conversation.title}
          </Typography>
          <Button autoFocus color="inherit" onClick={props.onDone}>
          {locale.Chat35.Interactive.Conversation.done}
          </Button>
        </Toolbar>
      </AppBar>
      <List>
        {
          conversations.map((conversation, index) => {
            return (
              <React.Fragment key={'conv_' + index}>
                <ListItem button onClick={() => {
                  if (props.onPicked) {
                    props.onPicked({index, conversation});
                  }
                }}>
                  <ListItemText
                    primary={conversation[0].content}
                    secondary={conversation[conversation.length - 1].content}
                    primaryTypographyProps={{ variant: 'body1', noWrap: true }}
                    secondaryTypographyProps={{ variant: 'body2', noWrap: true }}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            )
          })
        }

      </List>
      
    </>
  );
}
export default ConversationPicker;