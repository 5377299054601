import axios from "./index";
import { AUTH_REDIRECT_URI } from "../constants";

class AuthApi {
  static Login = (data) => {
    return axios.post(`auth/login`, data);
  };

  static LoginWithCode = (data) => {
    let redirect_uri = encodeURIComponent(AUTH_REDIRECT_URI);
    return axios.get(`auth/login_with_code?code=${data.code}&state=${data.state}&redirect_uri=${redirect_uri}`);
  };

  static CurrentUser = () => {
    return axios.get(`auth/current`);
  };

  static Register = (data) => {
    return axios.post(`users/register`, data);
  };

  static Authorize = (code) => {
    return axios.get(`sessions/oauth/github?code=${code}`);
  };

  static Logout = (data) => {
    // return axios.get(`auth/logout`, data, {
    //   headers: { Authorization: `${data.token}` },
    // });
    return axios.get(`auth/logout`);
  };
}

export default AuthApi;
