import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AuthApi from "../../api/auth";
import { useAuth } from "../../app_context/auth.context";
import { useLocale } from "../../app_context/locale.context";

function Copyright(props) {
  const { locale } = useLocale();
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        {locale.copyright}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn() {
  const { locale } = useLocale();
  const navigate = useNavigate();

  const { setUser } = useAuth();
  const { user } = useAuth();

  const [formData, setFormData] = React.useState({
    username: '',
    password: '',
    usernameError: null,
    passwordError: null,
  });
  const [error, setError] = React.useState(null);
  const refUsername = React.useRef();
  const refPassword = React.useRef();

  const validateFormData = (newFormData) => {
    if (newFormData.username.trim().length < 3) {
      newFormData.usernameError = locale.SignIn.userNameInvalid(3);
    } else {
      newFormData.usernameError = null;
    }
    if (newFormData.password.trim().length < 8) {
      newFormData.passwordError = locale.SignIn.passwordInvalid(8);
    } else {
      newFormData.passwordError = null;
    }

    setFormData(newFormData);

    return newFormData.usernameError == null && newFormData.passwordError == null;
  };

  const handleFormDataChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(null)
    if (!validateFormData({
      ...formData,
    })) {
      return;
    }
    AuthApi.Login(formData)
      .then((response) => {
        if (response.data.success) {
          //AuthApi.CurrentUser().then((resp) => { console.log(resp)});
          return setProfile(response);
        } else {
          setError(response.data.msg);
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  const setProfile = (response) => {
    let respUser = { ...response.data.data };
    respUser.token = respUser.username
    setUser(respUser);
    localStorage.setItem("user", JSON.stringify(respUser));
    return navigate("/Chat35/Interactive");
  };

  React.useEffect(() => {

    if (user && user.token && user.token !== "") {
      navigate("/Chat35/Interactive");
    }

  });

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {locale.SignIn.title}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label={locale.SignIn.userNameLabel}
              name="username"
              autoComplete="user name"
              autoFocus
              ref={refUsername}
              error={formData.usernameError !== null}
              helperText={formData.usernameError}
              onChange={handleFormDataChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={locale.SignIn.passwordLabel}
              type="password"
              id="password"
              autoComplete="current password"
              ref={refPassword}
              error={formData.passwordError !== null}
              helperText={formData.passwordError}
              onChange={handleFormDataChange}
            />
            {
              error && <FormHelperText error={error!=null}>{error}</FormHelperText>
            }
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {locale.SignIn.signInButton}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  {locale.SignIn.forgotPasswordLabel}
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {locale.SignIn.signUpLabel}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </>
  );
}