import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { useLocale } from "../../app_context/locale.context"

function Settings(props) {

  const { locale } = useLocale();
  const defaultSystemMessage = locale.Chat35.Settings.systemMessage;


  let aiSettings = localStorage.getItem('AI.Settings');
  aiSettings = aiSettings ? JSON.parse(aiSettings) : {
    temperature: 1,
    systemMessage: defaultSystemMessage
  };

  let [temperature, setTemperature] = React.useState(aiSettings.temperature);
  let [systemMessage, setSystemMessage] = React.useState(aiSettings.systemMessage);


  const saveSettings = () => {

    aiSettings.temperature = temperature;
    aiSettings.systemMessage = systemMessage;

    localStorage.setItem('AI.Settings', JSON.stringify(aiSettings));

    if (props.onDone) {
      props.onDone();
    }
  };

  const handleDefaultSettings = () => {
    setTemperature(1);
    setSystemMessage('');
  };

  return (
    <>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {locale.Chat35.Settings.title}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleDefaultSettings} sx={{mr: 3}}>
            {locale.Chat35.Settings.defaultSettingsButton}
          </Button>
          <Button autoFocus color="inherit" onClick={saveSettings}>
            {locale.Chat35.Settings.saveButton}
          </Button>
        </Toolbar>
      </AppBar>

      <Box sx={{mt:3, pl: 2, pr: 2}}>

        <Typography 
          variant="body2" 
          align="left" 
        >
          1. {locale.Chat35.Settings.temperatureLabel}
        </Typography>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{mt: 1}}
        >
          <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button onClick={() => { setTemperature(0.2); }}>{locale.Chat35.Settings.moreDeterministicButton}</Button>
                <Button onClick={() => { setTemperature(1); }}>{locale.Chat35.Settings.balanceButton}</Button>
                <Button onClick={() => { setTemperature(1.3); }}>{locale.Chat35.Settings.moreCreativityButton}</Button>
              </ButtonGroup>
        </Stack> 

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Typography variant="caption" align="right" sx={{width:80, pr: 1}}>
            {locale.Chat35.Settings.deterministicLabel}
          </Typography>
          <Slider min={0} max={1.6} step={0.01} defaultValue={0} value={temperature} onChange={(event) => { setTemperature(event.target.value) }} aria-label="Default" valueLabelDisplay="auto" />
          <Typography variant="caption" align="left" sx={{width:100, pl: 1}}>
            {locale.Chat35.Settings.creativityLabel}
          </Typography>
        </Stack>

      </Box>
      
      <Divider sx={{ height: 28, m: 0.5 }} orientation="horizontal" />

      <Box sx={{mt:5, pl: 2, pr: 2}}>
        <Typography 
            variant="body2" 
            align="left" 
          >
            2. {locale.Chat35.Settings.systemMessageLabel}
        </Typography>
        <TextField
          label=""
          multiline
          fullWidth
          maxRows={6}
          placeholder={defaultSystemMessage}
          onChange={(event) => { setSystemMessage(event.target.value); }}
          sx={{mt: 1}}
          value={systemMessage}
        />
      </Box>

    </>
  );
}
export default Settings;