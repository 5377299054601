import React from "react";
import PropTypes from "prop-types";

const LocaleContext = React.createContext(null);

export const LocaleProvider = ({value, children}) => {
  let [locale, setLocale] = React.useState(value);
  return <LocaleContext.Provider value={{ locale, setLocale }}>{children}</LocaleContext.Provider>;
};

LocaleProvider.propTypes = {
  locale: PropTypes.any,
  children: PropTypes.any,
};

export const useLocale = () => React.useContext(LocaleContext);
