import * as React from 'react';
import ChatApi from "../../api/chat";
import { useAuth } from "../../app_context/auth.context"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AvatarAssistant from '../components/AvatarAssistant';
import { useLocale } from "../../app_context/locale.context";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import SendPanel from './SendPanel';

// Speed dial
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HistoryIcon from '@mui/icons-material/History';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ConfirmDialog from '../components/ConfirmDialog';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import QuizIcon from '@mui/icons-material/Quiz';

// Message list
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { green } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dracula} from 'react-syntax-highlighter/dist/esm/styles/prism'

// History
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import ConversationPicker from "./ConversationPicker"

// Settings
import Settings from './Settings';

import { Paper } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

// System message picker
import SystemMessagePicker from './SystemMessagePicker';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function Interactive() {

  let { user } = useAuth();
  let { locale } = useLocale();
  let [anyError, setAnyError] = React.useState(null);
  
  let [useResponseStream, setUseResponseStream] = React.useState(true);
  let [isThinking, setThinking] = React.useState(false);
  let [typingMessage, setTypingMessage] = React.useState(null);
  let conversationsData = localStorage.getItem('conversations');
  let [chatMode, setChatMode] = React.useState(false);

  if (conversationsData) {
    let conversationsDataTmp = JSON.parse(conversationsData);
    conversationsData = [];
    // Correct the data
    for (let i = 0; i < conversationsDataTmp.length; i++) {
      if (conversationsDataTmp[i] instanceof Array && conversationsDataTmp[i].length > 0) {
        conversationsData.push(conversationsDataTmp[i]);
      }
    }
    localStorage.setItem('conversations', JSON.stringify(conversationsData));
  }
  if (!(conversationsData instanceof Array)) {
    conversationsData = [];
  }
  let [conversations, setConversations] = React.useState(conversationsData);
  let currentConversationIndexData = localStorage.getItem('currentConversationIndex');
  let [currentConversationIndex, setCurrentConversationIndex] = React.useState(currentConversationIndexData && currentConversationIndexData < conversationsData.length ? currentConversationIndexData : conversationsData.length - 1);

  const getMessageConfig = () => {
    let aiSettings = localStorage.getItem('AI.Settings');
    aiSettings = aiSettings ? JSON.parse(aiSettings) : null;
    let systemMessage = aiSettings && aiSettings.systemMessage && aiSettings.systemMessage.trim() !== '' ? aiSettings.systemMessage : locale.Chat35.Settings.systemMessage;
    let temperature = aiSettings && typeof aiSettings.temperature === 'number' ? aiSettings.temperature : 0.8;
    return {systemMessage, temperature};
  };

  let [messageConfig, setMessageConfig] = React.useState(getMessageConfig());

  // Define the message list, init with the last conversation, or a system message
  // If there is no conversation, init with a system message
  // If the last conversation is empty or the item 0 is not a system message, add a system message
  // let messagesData = conversations.length > 0 && conversations[currentConversationIndex] ? conversations[currentConversationIndex] : [];
  // if (messagesData.length === 0 || messagesData[0].role !== 'system') {
  //   messagesData.unshift({'role': 'system', 'content': messageConfig.systemMessage});
  // }
  let [messages, setMessages] = React.useState([]);
  
  let lastMessageItemRef = React.createRef();

  let [openHistory, setOpenHistory] = React.useState(false);
  let [openSettings, setOpenSettings] = React.useState(false);

  let [openCleanConversationDialog, setOpenCleanConversationDialog] = React.useState(false);

  let [currentAudio, setCurrentAudio] = React.useState(null);
  let [currentAudioIndex, setCurrentAudioIndex] = React.useState(null);
  let [currentAudioAction, setCurrentAudioAction] = React.useState(null);
  let [audioCacheDict, setAudioCacheDict] = React.useState({});
  let [hasCognitiveServiceAccess, setHasCognitiveServiceAccess] = React.useState(false);
  let [inputPanelHeight, setInputPanelHeight] = React.useState(175);
  let [speechTheReply, setSpeechTheReply] = React.useState(false);
  let [currentOpenedMessageMoreButtonIndex, setCurrentOpenedMessageMoreButtonIndex] = React.useState(null);

  let [openSystemMessagePicker, setOpenSystemMessagePicker] = React.useState(false);

  let [copied, setCopied] = React.useState(false);
  let [expandInputPanel, setExpandInputPanel] = React.useState(true);
  
  const handleCloseSystemMessagePicker = (systemMessage) => {
    setOpenSystemMessagePicker(false);
    if (systemMessage) {
      cleanAudioCache();
      setMessages([{'role': 'system', 'content': systemMessage}]);
      setCurrentConversationIndex(conversations.length);
      //setConversations([...conversations, messages]);
    }
  };

  
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAnyError(null);
  };

  const cleanAudioCache = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }
    setCurrentAudio(null);
    setCurrentAudioIndex(null);
    setAudioCacheDict({});
  };

  const handleNewChatClick = () => {
    setOpenSystemMessagePicker(true);
  }; 

  const handleChatModeClick = () => {
    setChatMode(!chatMode);
  };
  

  const handleExpandInputPanelClick = () => {
    if (expandInputPanel) {
      setInputPanelHeight(43);
    } else { 
      setInputPanelHeight(175);
    }
    setExpandInputPanel(!expandInputPanel);
  };

  const handleCleanConversationClick = (event) => {
    setOpenCleanConversationDialog(true);
  };

  const scrollToLastMessage = () => {
    if (lastMessageItemRef && lastMessageItemRef.current) {
      lastMessageItemRef.current.scrollIntoView({ behavior: 'auto' });
    }
  };

  const saveConversation = (messages) => {
    if (messages === null) {
      return;
    }

    let newConversations = [...conversations];
    if (newConversations.length < 1) {
      setCurrentConversationIndex(0);
      setConversations(newConversations = [messages]);
    } else {
      newConversations[currentConversationIndex] = messages;
      setConversations(newConversations);
    }
    localStorage.setItem('conversations', JSON.stringify(newConversations));
  };

  const handleClickOpenHistory = () => {
    setOpenHistory(true);
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
    // update the message config
    setMessageConfig(getMessageConfig());
  };

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleCleanConversationDialogClose = (confirm) => {
    if (confirm) {
      // clean the audio cache
      cleanAudioCache();
      // clean the current conversation
      conversations[currentConversationIndex] = null; // mark as would be deleted
      let newConversations = [];
      for (let i = 0; i < conversations.length; i++) {
        if (conversations[i] !== null && conversations[i].length > 0) {
          newConversations.push(conversations[i]);
        }
      }
      setConversations(newConversations);
      localStorage.setItem('conversations', JSON.stringify(newConversations));

      // start a new conversation
      let newMessages = [{'role': 'system', 'content': messageConfig.systemMessage}];
      setMessages(newMessages);
      setCurrentConversationIndex(newConversations.length);
    }
    
    setOpenCleanConversationDialog(false);
  };

  const handleDeleteMessageClick = (index, msg) => {
    let newMessages = [...messages];
    newMessages.splice(index, 1);
    setMessages(newMessages);
    saveConversation(newMessages);
  };

  const handleSpeechClick = (index, message, action) => {

    setCurrentAudioAction(action);

    // Action can be 'play' or 'pause' or 'stop'
    if (action === 'pause' || action === 'stop') {
      currentAudio.pause();
      if (action === 'stop') {
        currentAudio.currentTime = 0;
        setCurrentAudio(null);
        setCurrentAudioAction(null);
        setCurrentAudioIndex(null);
      }
      return;
    } else if (action === 'play') {
      // Continue to play
      if (currentAudio != null && currentAudioIndex === index) {
        currentAudio.play();
        return;
      }
      // Play a new one
      // 1.Stop the current one
      // 2.Set the current one to the new one
      if (currentAudio != null) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
      setCurrentAudio(null);
      setCurrentAudioIndex(null);
      setCurrentAudioAction(null);

      if (!audioCacheDict[index]) { // Load audio from remote
        
        audioCacheDict[index] = ChatApi.Speech(message.content);
        audioCacheDict[index].addEventListener('canplay', () => { 
          // console.log('canplay'); 
        });
        setAudioCacheDict(audioCacheDict);
      }
      // Load audio from cache
      currentAudio = audioCacheDict[index];

      if (!currentAudio.error) {
        currentAudio.play();
        setCurrentAudio(currentAudio);
        setCurrentAudioIndex(index);
        setCurrentAudioAction('play');
        currentAudio.addEventListener('ended', function(){
          currentAudio.currentTime = 0;
          setCurrentAudio(null);
          setCurrentAudioIndex(null);
          setCurrentAudioAction(null);
        });
        currentAudio.addEventListener('error', function(){
          currentAudio.currentTime = 0;
          setCurrentAudio(null);
          setCurrentAudioIndex(null);
          setCurrentAudioAction(null);
          delete audioCacheDict[index];
          setAudioCacheDict(audioCacheDict);
          setAnyError('Play sound error');
        });
  
      } else {
        setCurrentAudio(null);
        setCurrentAudioIndex(null);
        setCurrentAudioAction(null);
        delete audioCacheDict[index];
        setAudioCacheDict(audioCacheDict);
        setAnyError(currentAudio.error);
      }
    }
    
  };

  // const handleVoiceRecognitionDone = async (voiceText) => {
  //   setOpenVoiceRecognition(false);
  //   if (voiceText) {
  //     await setText(voiceText);
  //     await traceTextLength(voiceText);
  //     await setSpeechTheReply(true);
  //     handleSendClick();
  //   }
  // };

  const handleSendPanelOnSent = (newMessages) => {
    saveConversation(newMessages);
    setMessages(newMessages);
    if (!useResponseStream) {
      setThinking(true);
    }
  };

  const handleSendPanelOnTyping = (text) => {
    setTypingMessage(msg => (msg || '') + text);
  };

  const handleSendPanelOnTypeDone = (newMessages, sentBySpeech) => {
    saveConversation(newMessages);
    setTypingMessage(null);
    setMessages(newMessages);
    
    // Speech the reply if needed
    if (sentBySpeech && newMessages[newMessages.length - 1].role === 'assistant') {
      setTimeout(() => {
        handleSpeechClick(newMessages.length - 1, newMessages[newMessages.length - 1], 'play');
      }, 500);
    }
  };

  const handleSendPanelOnReply = async (newMessages, sentBySpeech) => {
    
    await saveConversation(newMessages);
    await setMessages(newMessages);

    // Speech the reply if needed
    if (sentBySpeech && newMessages[newMessages.length - 1].role === 'assistant') {
      setTimeout(() => {
        handleSpeechClick(newMessages.length - 1, newMessages[newMessages.length - 1], 'play');
      }, 500);
    }
  };

  const handleSendPanelOnError = (error, newMessages) => {
    setAnyError(error);
    if (newMessages) {
      saveConversation(newMessages);
      setMessages(newMessages);
    }
  };

  const handleSendPanelOnFinished = (newMessages) => {
    setThinking(false);
  };

  const handleCopyMessagesClick = () => {
    let text = '';
    for (let i = 0; i < messages.length; i++) {
      text += `${messages[i].role === 'assistant' ? locale.assistant : locale.you}:\n${messages[i].content}${i === messages.length - 1 ? '' : '\n\n\n'}`;
    }
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  React.useEffect(() => {
    if (currentAudio == null) {
      scrollToLastMessage();
    }
  }, [messages, typingMessage]);

  React.useEffect(() => {
    localStorage.setItem('currentConversationIndex', currentConversationIndex);
  }, [currentConversationIndex]);

  React.useEffect(() => {

    // Load Azure access token to decide whether to show the voice recognition button
    ChatApi.AzureAccessToken().then((resp) => {
      if (resp.data.code === 200) {
        setHasCognitiveServiceAccess(true);
      }
    }).catch((error) => {
      setHasCognitiveServiceAccess(false);
    });

    let messagesData = conversations.length > 0 && conversations[currentConversationIndex] ? conversations[currentConversationIndex] : [];
    if (messagesData.length === 0 || messagesData[0].role !== 'system') {
      messagesData.unshift({'role': 'system', 'content': messageConfig.systemMessage});
    }
    setMessages(messagesData);

  }, []);

  return (
    <>
      <List sx={{position: 'fixed', bottom: inputPanelHeight, top: 60, left: 0, right: 0, overflow: 'auto'}} elevation={0}>

        {
          messages.map((message, index) => {
            return (
              <React.Fragment key={'msg_' + index}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {message.role === 'assistant' && 
                  <AvatarAssistant/>}
                  {(message.role === 'user' || message.role === 'system') && <Avatar alt={user.username} src="#" />}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <ReactMarkdown 
                      children={message.content} 
                      remarkPlugins={[remarkGfm, {}]}
                      components={{
                        code({node, inline, className, children, ...props}) {
                          const match = /language-(\w+)/.exec(className || '')
                          return !inline ? (
                            <SyntaxHighlighter
                              children={String(children).replace(/\n$/, '')}
                              style={dracula}
                              language={match ? match[1] : 'text'}
                              PreTag="div"
                              wrapLongLines={true}
                              {...props}
                            />
                          ) : (
                            <code className={className} {...props}>
                              {children}
                            </code>
                          )
                        }
                      }}
                      />
                    </React.Fragment>
                  }
                />
              </ListItem>

              { message.role !== 'system' &&
              <Stack
                direction="row"
                component="li"
                pl={8}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                  sx={{
                    mr: 1,
                  }}>
                  {/* <ClickAwayListener onClickAway={(event) => { console.log(event.target);setCurrentOpenedMessageMoreButtonIndex(null); }}> */}
                    <IconButton 
                      sx={{color: 'rgba(0, 0, 0, 0.4)'}} 
                      onClick={(event) => { setCurrentOpenedMessageMoreButtonIndex((curMoreBtnIndex) => curMoreBtnIndex === index ? null : index); }}>
                      {
                        currentOpenedMessageMoreButtonIndex === index ?
                        <KeyboardArrowLeftIcon/>
                        :
                        <KeyboardArrowRightIcon/>
                      }
                    </IconButton>
                  {/* </ClickAwayListener> */}
                  {
                    currentOpenedMessageMoreButtonIndex === index &&
                    <IconButton onClick={(event) => { handleDeleteMessageClick(index, message); setCurrentOpenedMessageMoreButtonIndex(null); }}>
                      <DeleteOutlinedIcon/>
                    </IconButton>
                  }
                </Stack>
                <Stack 
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{
                    mr: 2,
                  }}>
                  {hasCognitiveServiceAccess &&
                    <Box>
                      {
                        currentAudioIndex != index &&
                        <IconButton sx={{color: 'rgba(0, 0, 0, 0.4)'}} aria-label="Speech" onClick={() => { handleSpeechClick(index, message, 'play'); }}>
                          <VolumeUpIcon/>
                        </IconButton>
                      }
                      {
                        currentAudioIndex == index && currentAudio != null &&
                        <>
                        <IconButton color="default" aria-label="Speech" onClick={() => { handleSpeechClick(index, message, 'stop'); }}>
                          <StopCircleIcon/>
                        </IconButton>
                        {
                          currentAudioAction === 'play' &&
                          <IconButton color="default" aria-label="Speech" onClick={() => { handleSpeechClick(index, message, 'pause'); }}>
                            <PauseCircleFilledIcon/>
                          </IconButton>
                        }
                        {
                          currentAudioAction === 'pause' &&
                          <IconButton color="default" aria-label="Speech" onClick={() => { handleSpeechClick(index, message, 'play'); }}>
                            <PlayCircleIcon />
                          </IconButton>
                        }
                        </>
                      }
                    </Box>
                  }
                </Stack>
              </Stack>
              }
              {index < messages.length - 1 && <Divider variant="inset" light sx={{marginRight: 2}} component="li" />}
              </React.Fragment>
            )
          })
        }

        {isThinking &&
          <React.Fragment key={'msg_assistant_thinking'}>
            <Divider variant="inset" light sx={{marginRight: 2}} component="li" />
            <ListItem>
              <ListItemAvatar>
                <AvatarAssistant/>
              </ListItemAvatar>
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {locale.Chat35.Interactive.assistantThinking}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </React.Fragment>
        }

        {typingMessage &&
          <React.Fragment key={'msg_assistant_typing'}>
            <Divider variant="inset" light sx={{marginRight: 2}} component="li" />
            <ListItem>
              <ListItemAvatar>
                <AvatarAssistant/>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <ReactMarkdown 
                      children={typingMessage}
                      remarkPlugins={[remarkGfm, {}]}
                      components={{
                        code({node, inline, className, children, ...props}) {
                          const match = /language-(\w+)/.exec(className || '')
                          return !inline ? (
                            <SyntaxHighlighter
                              children={String(children).replace(/\n$/, '')}
                              style={dracula}
                              language={match ? match[1] : 'text'}
                              PreTag="div"
                              wrapLongLines={true}
                              {...props}
                            />
                          ) : (
                            <code className={className} {...props}>
                              {children}
                            </code>
                          )
                        }
                      }}
                    />
                    
                  </React.Fragment>
                }
              />
            </ListItem>
          </React.Fragment>
        }

        <Divider ref={lastMessageItemRef} variant="inset" light sx={{marginRight: 2, borderColor: 'transparent'}} component="li"/>
      </List>

      <Paper elevation={5} sx={{position: 'fixed', overflow: 'hidden', height: inputPanelHeight, bottom: 0, right: 0, left: 0,}}>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={0}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={0}
          >
            <Box>
              <IconButton aria-label={locale.Chat35.Interactive.Dial.Settings}
                onClick={handleOpenSettings}
              >
                <SettingsOutlinedIcon />
              </IconButton>
              <IconButton sx={{ml: 1}} aria-label={locale.Chat35.Interactive.Dial.CleanConversation}
                onClick={handleCleanConversationClick}
              >
                <DeleteOutlinedIcon />
              </IconButton>
              <IconButton sx={{ml: 1}} aria-label={locale.Chat35.Interactive.Dial.History}
                onClick={handleClickOpenHistory}
              >
                <HistoryIcon />
              </IconButton>
              <IconButton sx={{ml: 1}} aria-label={locale.Chat35.Interactive.Dial.copy}
                onClick={handleCopyMessagesClick}
              >
                {copied ? <CheckCircleOutlineIcon color="success"/> : <ContentCopyIcon /> }
              </IconButton>
              <IconButton sx={{ml: 1}} aria-label={locale.Chat35.Interactive.Dial.newChat}
                onClick={handleNewChatClick}
              >
                <AddCircleOutlineIcon />
              </IconButton>
              <IconButton sx={{ml: 1}} aria-label={locale.Chat35.Interactive.Dial.chatMode}
                onClick={handleChatModeClick}
              >
                {chatMode ? <QuestionAnswerIcon color="success"/> : <QuestionAnswerIcon /> }
              </IconButton>
            </Box>
            <Box>
              <IconButton sx={{ml: 1}}
                onClick={handleExpandInputPanelClick}
              >
                {expandInputPanel ? <ExpandMoreIcon /> : <ExpandLessIcon /> }
              </IconButton>
            </Box>
          </Stack>
          <SendPanel chatMode={chatMode} useResponseStream={useResponseStream} messageConfig={messageConfig} messages={messages} enableSpeechRecognition={hasCognitiveServiceAccess} onSent={handleSendPanelOnSent} onReply={handleSendPanelOnReply} onError={handleSendPanelOnError} onTyping={handleSendPanelOnTyping} onTypeDone={handleSendPanelOnTypeDone} onFinished={handleSendPanelOnFinished} />
        </Stack>
        
      </Paper>


      <Dialog
        fullScreen
        open={openHistory}
        onClose={handleCloseHistory}
        TransitionComponent={Transition}
      >
        <ConversationPicker onDone={handleCloseHistory} onPicked={(result) => {
          cleanAudioCache();
          setMessages(result.conversation);
          setCurrentConversationIndex(result.index);
          handleCloseHistory();
        }} />
      </Dialog>

      <Dialog
        fullScreen
        open={openSettings}
        TransitionComponent={Transition}
      >
        <Settings onDone={handleCloseSettings} />
      </Dialog>

      <Dialog
        fullScreen
        open={openSystemMessagePicker}
        TransitionComponent={Transition}
      >
        <SystemMessagePicker onDone={handleCloseSystemMessagePicker} />
      </Dialog>

      <Snackbar open={anyError!==null} autoHideDuration={6000} onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" severity="error" sx={{ width: '100%' }}>
          {anyError}
        </MuiAlert>
      </Snackbar>

      <ConfirmDialog title={locale.Chat35.Interactive.Dial.CleanConversationConfirm.title} description={locale.Chat35.Interactive.Dial.CleanConversationConfirm.description} buttonNO={locale.Chat35.Confirm.no} buttonYES={locale.Chat35.Confirm.yes} open={openCleanConversationDialog} onClose={handleCleanConversationDialogClose} />
    </>
  );
}
export default Interactive;