export const enUS = {
    name: 'en-US',
    copyright: 'www.ailaoshi.net',
    you: 'You',
    assistant: 'Assistant',
    Common: {
        profile: 'Profile',
        logout: 'Logout',
        tryAgain: 'Try Again',
    },
    SignIn: {
        title: 'Sign In',
        userNameInvalid: (letters) => {
            return `Username must be at least ${letters} characters`;
        },
        passwordInvalid: (letters) => {
            return `Password must be at least ${letters} characters`;
        },
        userNameLabel: 'User Name',
        passwordLabel: 'Password',
        forgotPasswordLabel: 'Forgot password?',
        signUpLabel: 'Don\'t have an account? Sign Up',
        signInButton: 'SIGN IN',
    },
    Chat35: {
        title: 'AI Assistant',
        Confirm: {
            yes: 'YES',
            no: 'NO',
        },
        Interactive: {
            assistantThinking: 'Assistant is thinking...',
            Dial: {
                newChat: 'New Chat',
                history: 'History',
                cleanConversation: 'Clean Conversation',
                settings: 'Settings',
                CleanConversationConfirm: {
                    title: 'Clean Conversation',
                    description: 'Are you sure you want to clean the conversation?',
                },
                copy: 'Copy to Clipboard',
                chatMode: 'Chat Mode'
            },
            Conversation: {
                title: 'Conversations',
                done: 'Close',
            },
            VoiceRecognition: {
                title: 'Voice Recognition', // deprecated
                recording: 'Recording', // deprecated
                recognizing: 'Recognizing', // deprecated
                inputLabel: 'Recognized Text', // deprecated
                inputPlaceholder: 'Please input text',
                send: 'Send'
            }
        },
        Settings: {
            systemMessage: 'You are a friendly and helpful teaching assistant. You explain concepts in great depth using simple terms, and you give examples to help people learn.',
            systemMessageLabel: '(Default) Set the behavior of the assistant. The assistant was instructed with the following:',
            title: 'Settings',
            defaultSettingsButton: 'Default Settings',
            saveButton: 'Save',
            temperatureLabel: 'Let the Assistant be:',
            moreDeterministicButton: 'More Deterministic',
            balanceButton: 'Balance',
            moreCreativityButton: 'More Creativity',
            deterministicLabel: 'Deterministic',
            creativityLabel: 'Creativity',
        },
        SystemMessagePicker: {
            title: 'Prompts',
            description: 'Design your prompts to help the AI Assistant understand your needs better',
            closeButton: 'Cancel',
            startButton: 'Start Chatting',
            deleteTemplate: 'Delete Template',
            deleteTemplateConfirm: 'Are you sure you want to delete this template?',
            Storagekey: {
                myTemplates: 'My Templates',
                allTypes: 'All Types',
            }
        }
    }
};

export const zhCN = {
    name: 'zh-CN',
    copyright: 'www.ailaoshi.net',
    you: '你',
    assistant: '助手',
    Common: {
        profile: '个人中心',
        logout: '退出登录',
        tryAgain: '重试',
    },
    SignIn: {
        title: '登录',
        userNameInvalid: (letters) => {
            return `用户名至少 ${letters} 个字符`;
        },
        passwordInvalid: (letters) => {
            return `密码至少 ${letters} 个字符`;
        },
        userNameLabel: '用户名',
        passwordLabel: '密码',
        forgotPasswordLabel: '忘记密码?',
        signUpLabel: '注册',
        signInButton: '登录',
    },
    Chat35: {
        title: 'AI助手',
        Confirm: {
            yes: '确定',
            no: '取消',
        },
        Interactive: {
            assistantThinking: '助手在思考...',
            Dial: {
                newChat: '新对话',
                history: '对话记录',
                cleanConversation: '清空对话内容',
                settings: '设置',
                CleanConversationConfirm: {
                    title: '清空对话内容',
                    description: '你确定要清空当前的对话内容吗?',
                },
                copy: '复制到剪贴板',
                chatMode: '聊天模式'
            },
            Conversation: {
                title: '会话记录',
                done: '关闭',
            },
            VoiceRecognition: {
                title: '语音识别', // deprecated
                recording: '录音中...', // deprecated
                recognizing: '识别中...', // deprecated
                inputLabel: '识别结果', // deprecated
                inputPlaceholder: '请输入文字',
                send: '发送'
            }
        },
        Settings: {
            systemMessage: '你是一个友好和很有帮助的助教。你用简单的语言深入浅出地解释概念，并举例说明，帮助人们学习。',
            systemMessageLabel: '（默认设置）引导助手的行为，助手被指导为:',
            title: '设置',
            defaultSettingsButton: '默认设置',
            saveButton: '保存',
            temperatureLabel: '你希望助手:',
            moreDeterministicButton: '更加严谨',
            balanceButton: '平衡',
            moreCreativityButton: '更有创意',
            deterministicLabel: '严谨',
            creativityLabel: '创意',
        },
        SystemMessagePicker: {
            title: '引导语',
            description: '设计你的引导语，让AI助手更好的理解你的需求',
            closeButton: '取消',
            startButton: '开始对话',
            deleteTemplate: '删除模板',
            deleteTemplateConfirm: '你确定要删除这个模板吗?',
            Storagekey: {
                myTemplates: '我的模板',
                allTypes: '所有',
            }
        },
    }
};

export const zh = zhCN;
export const amET = enUS;
export const arEG = enUS;
export const arSA = enUS;
export const arSD = enUS;
export const azAZ = enUS;
export const bnBD = enUS;
export const beBY = enUS;
export const bgBG = enUS;
export const caES = enUS;
export const csCZ = enUS;
export const daDK = enUS;
export const deDE = enUS;
export const elGR = enUS;
export const esES = enUS;
export const etEE = enUS;
export const faIR = enUS;
export const fiFI = enUS;
export const frFR = enUS;
export const heIL = enUS;
export const hiIN = enUS;
export const hrHR = enUS;
export const huHU = enUS;
export const hyAM = enUS;
export const idID = enUS;
export const isIS = enUS;
export const itIT = enUS;
export const jaJP = enUS;
export const khKH = enUS;
export const koKR = enUS;
export const kuLatn = enUS;
export const kkKZ = enUS;
export const mkMK = enUS;
export const nbNO = enUS;
export const nlNL = enUS;
export const plPL = enUS;
export const ptBR = enUS;
export const ptPT = enUS;
export const roRO = enUS;
export const srRS = enUS;
export const ruRU = enUS;
export const siLK = enUS;
export const skSK = enUS;
export const svSE = enUS;
export const thTH = enUS;
export const trTR = enUS;
export const ukUA = enUS;
export const urPK = enUS;
export const viVN = enUS;
export const zhHK = enUS;
export const zhTW = enUS;