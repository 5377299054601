import * as React from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useAuth } from "../../app_context/auth.context"
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { useLocale } from "../../app_context/locale.context"

import AuthApi from "../../api/auth";

import './Chat35.css';
import axios from 'axios';
import AvatarAssistant from '../components/AvatarAssistant';
import { green } from '@mui/material/colors';
import { useTheme } from '@emotion/react';
import ChatApi from "../../api/chat";
import CssBaseline from '@mui/material/CssBaseline';
import { AUTH_LOGOUT_URL } from '../../constants';


function Chat35() {

  let { locale } = useLocale();
  let { user, setUser } = useAuth();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [isCheckedUserStatus, setIsCheckedUserStatus] = React.useState(false);

  const pages = [{name:'Home', to:'/Home'}, {name:'Interactive', to:'/Chat35/Interactive'}];
  const settings = [locale.Common.logout];

  const cleanToken = () => {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    delete user.token;
    localStorage.setItem("user", JSON.stringify(user));
    window.location.replace(AUTH_LOGOUT_URL);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickSettingItem = (setting) => {
    
    if (setting === locale.Common.logout) {
      
      AuthApi.Logout().then(() => {
        cleanToken();
      });
    }
  };

  const fetchCurrentUser = () => {
    // Just check the session is live or not
    AuthApi.CurrentUser().finally(() => {
      setIsCheckedUserStatus(true);
    });
  };

  React.useEffect(() => {
    fetchCurrentUser();
  }, []);

  return (
    isCheckedUserStatus &&
    <Box sx={{display: 'flex'}}>
    <AppBar component="nav" elevation={5}>
      <Container maxWidth="fullwidth">
        <Toolbar disableGutters>
          <AvatarAssistant sx={{ bgcolor: green[500], mr: 1, width: 20, height: 20 }}/>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {locale.Chat35.title}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={locale.Common.profile}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={user.username} src="#" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleClickSettingItem(setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>

    <Outlet />

    </Box>
  );
}
export default Chat35;