import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLocale } from "../../app_context/locale.context"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import ChatApi from '../../api/chat';
import PropTypes from 'prop-types';
import Tabs, {tabsClasses} from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import { TruncateText2 } from '../../utils';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import ConfirmDialog from '../components/ConfirmDialog';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


function SystemMessagePicker(props) {

  let aiSettings = localStorage.getItem('AI.Settings');
  aiSettings = aiSettings ? JSON.parse(aiSettings) : null;
  const { locale } = useLocale();
  const defaultSystemMessage = locale.Chat35.Settings.systemMessage;
  let [systemMessage, setSystemMessage] = React.useState(aiSettings && aiSettings.systemMessage && aiSettings.systemMessage !== '' ? aiSettings.systemMessage : defaultSystemMessage);

  // Attach to templates as 'MyTemplates' category
  let STORAGE_KEY_MY_PROMPT_TEMPLATES = locale.Chat35.SystemMessagePicker.Storagekey.myTemplates;
  let STORAGE_KEY_MY_PROMPT_TEMPLATES_ALLTYPES = locale.Chat35.SystemMessagePicker.Storagekey.allTypes;
  let myTemplatesData = localStorage.getItem(STORAGE_KEY_MY_PROMPT_TEMPLATES);
  let [myTemplates, setMyTemplates] = React.useState(myTemplatesData ? JSON.parse(myTemplatesData) : {});
  myTemplatesData = {};
  myTemplatesData[STORAGE_KEY_MY_PROMPT_TEMPLATES] = myTemplates;
  let [templates, setTemplates] = React.useState(myTemplatesData);

  let [categorySelected, setCategorySelected] = React.useState(0);
  let [typeSelected, setTypeSelected] = React.useState(0);
  let [openDeleteTemplateDialog, setOpenDeleteTemplateDialog] = React.useState(false);
  let [currentTemplateContent, setCurrentTemplateContent] = React.useState(null);
  
  const handleDeleteTemplateDialogClose = (confirm) => {
    if (confirm) {
      // Delete from localStorage
      let myTemplatesData = localStorage.getItem(STORAGE_KEY_MY_PROMPT_TEMPLATES);
      let myTemplates = myTemplatesData ? JSON.parse(myTemplatesData) : null;
      if (myTemplates) {
        let newTemplates = [];
        for (let i = 0; i < myTemplates[STORAGE_KEY_MY_PROMPT_TEMPLATES_ALLTYPES].length; i++) {
          if (myTemplates[STORAGE_KEY_MY_PROMPT_TEMPLATES_ALLTYPES][i].content !== currentTemplateContent) {
            newTemplates.push(myTemplates[STORAGE_KEY_MY_PROMPT_TEMPLATES_ALLTYPES][i]);
          }
        }
        myTemplates[STORAGE_KEY_MY_PROMPT_TEMPLATES_ALLTYPES] = newTemplates;

        localStorage.setItem(STORAGE_KEY_MY_PROMPT_TEMPLATES, JSON.stringify(myTemplates));
        setMyTemplates(myTemplates);
        templates[STORAGE_KEY_MY_PROMPT_TEMPLATES] = myTemplates;
        setTemplates(templates);
      }
    }
    
    setOpenDeleteTemplateDialog(false);
  };

  const handleCloseClick = () => {
    if (props.onDone) {
      props.onDone();
    }
  };

  const handleStartConversationButtonClick = (templateContent, isCustom) => {

    if (isCustom) {
      // Save to localStorage
      let title = TruncateText2(templateContent, 18);
      let myTemplatesData = localStorage.getItem(STORAGE_KEY_MY_PROMPT_TEMPLATES);
      let myTemplates = myTemplatesData ? JSON.parse(myTemplatesData) : {};
      if (!myTemplates[STORAGE_KEY_MY_PROMPT_TEMPLATES_ALLTYPES]) {
        myTemplates[STORAGE_KEY_MY_PROMPT_TEMPLATES_ALLTYPES] = [];
      }
      // Check if already exists
      let found = false;
      for (let i = 0; i < myTemplates[STORAGE_KEY_MY_PROMPT_TEMPLATES_ALLTYPES].length; i++) {
        if (myTemplates[STORAGE_KEY_MY_PROMPT_TEMPLATES_ALLTYPES][i].content === templateContent) {
          found = true;
          break;
        }
      }
      if (!found) {
        myTemplates[STORAGE_KEY_MY_PROMPT_TEMPLATES_ALLTYPES].push({ title, content: templateContent });
        localStorage.setItem(STORAGE_KEY_MY_PROMPT_TEMPLATES, JSON.stringify(myTemplates));
      }
    }

    if (props.onDone) {
      props.onDone(templateContent);
    }
  };

  const handleCategoryChange = (event, newValue) => {
    setCategorySelected(newValue);
    setTypeSelected(0);
  };
  const handleTypeChange = (event, newValue) => {
    setTypeSelected(newValue);
  };

  const sortKeys = (obj) => {
    return Object.keys(obj).sort((a, b) => {
      if (a === STORAGE_KEY_MY_PROMPT_TEMPLATES || a === STORAGE_KEY_MY_PROMPT_TEMPLATES_ALLTYPES) {
        return -1;
      } else if (b === "ABC" || b === "DEF") {
        return 1;
      } else {
        return a.localeCompare(b);
      }
    });
  };

  React.useEffect(() => {
    // Fetch prompt_templates
    ChatApi.PromptTemplates().then((resp) => {
      
      if (resp.data.code === 200) {
        // Copy myTemplates to new object
        setTemplates({...resp.data.data, ...templates});  
      }
    });

  }, []);

  return (
    <>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {locale.Chat35.SystemMessagePicker.title}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleCloseClick}>
            {locale.Chat35.SystemMessagePicker.closeButton}
          </Button>
        </Toolbar>
      </AppBar>

      <Box sx={{p: 1}}>
        <Card sx={{mt: 1}} elevation={3}>
          <CardContent>
            <Typography variant="body2" component="div">
              {locale.Chat35.SystemMessagePicker.description}
            </Typography>
            <TextField
              label=""
              multiline
              fullWidth
              maxRows={6}
              placeholder={defaultSystemMessage}
              onChange={(event) => { setSystemMessage(event.target.value); }}
              sx={{mt: 1}}
              value={systemMessage}
            />
          </CardContent>
          <CardActions sx={{justifyContent: 'flex-end'}}>
            <Button variant="contained" size="small" onClick={() => {handleStartConversationButtonClick(systemMessage, true);}}>{locale.Chat35.SystemMessagePicker.startButton}</Button>
          </CardActions>
        </Card>
      </Box>

      <Box sx={{p: 1, mt: 2}}>
        <Box          
          sx={{ flexGrow: 1, bgcolor: 'background.paper'}}
        >
          <Tabs
            scrollButtons
            variant="scrollable"
            value={categorySelected}
            onChange={handleCategoryChange}
            aria-label="Template categories"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              }
            }}
            
          >
          {
            templates && sortKeys(templates).map((category, index) => {
              return (
                <Tab key={`category_tab_${index}`} label={category} {...a11yProps(index)} />
              );
            })
          }
          </Tabs>
          {
            templates && sortKeys(templates).map((category, index) => {
              return (
                <TabPanel value={categorySelected} index={index} key={`category_panel_${index}`}>
                  
                  <Box
                    sx={{
                      flexGrow: 1,
                      bgcolor: 'background.paper',
                      display: 'flex' 
                    }}
                  >
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={typeSelected}
                      aria-label="Template types"
                      onChange={handleTypeChange}
                      sx={{ borderRight: 1, borderColor: 'divider', minWidth: 80 }}
                    >
                    {
                      templates[category] && Object.keys(templates[category]).map((type, indexOfType) => {
                        return (
                          <Tab key={`type_tab_${indexOfType}`} label={type} {...a11yProps(indexOfType)} />
                        );
                      })
                    }
                    </Tabs>
                    {
                      templates[category] && Object.keys(templates[category]).map((type, indexOfType) => {
                        return (
                          <TabPanel value={typeSelected} index={indexOfType} key={`templates_panel_${indexOfType}`}>
                            <Grid container spacing={2}>
                            {
                              templates[category][type].map((template, indexOfTemplate) => {
                                return (
                                  <Grid item xs={12} md={6} xl={4} key={`templates_grid_${indexOfTemplate}`}>
                                    <Card key={`templates_card_${indexOfTemplate}`} sx={{mt: 1}}>
                                      <CardContent>
                                        <Typography variant="h5" component="div">
                                          {template.title}
                                        </Typography>
                                        <Typography variant="body2">
                                          {template.content}
                                        </Typography>
                                      </CardContent>
                                      <CardActions sx={{justifyContent: 'flex-end'}}>
                                      {
                                        category === STORAGE_KEY_MY_PROMPT_TEMPLATES &&
                                        <IconButton size="small" onClick={(event) => { setOpenDeleteTemplateDialog(true); setCurrentTemplateContent(template.content); }}>
                                          <DeleteOutlinedIcon/>
                                        </IconButton>
                                      }

                                        <Button size="small" onClick={() => {handleStartConversationButtonClick(template.content);}}>{locale.Chat35.SystemMessagePicker.startButton}</Button>
                                      </CardActions>
                                    </Card>
                                  </Grid>
                                );
                              })
                            }
                            </Grid>
                          </TabPanel>
                        );
                      })
                    }
                  </Box>

                </TabPanel>
              );
            })
          }
        </Box>

      </Box>

      <ConfirmDialog title={locale.Chat35.SystemMessagePicker.deleteTemplate} description={locale.Chat35.SystemMessagePicker.deleteTemplateConfirm} buttonNO={locale.Chat35.Confirm.no} buttonYES={locale.Chat35.Confirm.yes} open={openDeleteTemplateDialog} onClose={handleDeleteTemplateDialogClose} />

    </>
  );
}
export default SystemMessagePicker;